import React from 'react';
import {
    isMobile,
    isMobileSafari
} from "react-device-detect";
import './styles/global.css';
import BaseContainer from './BaseContainer'
import { GET } from './api/ChatServices'
import ChatServices from './api/ChatServices'
var QRCode = require('qrcode.react');

let currentLink = "https://www.hb-wallet.com/"
export default class Community extends BaseContainer {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true
        }
        this.anchorElement = null;
    }

    async componentDidMount() {

        const { commId } = this.props.match.params
        try {
            let data = await ChatServices.requestData(GET, process.env.REACT_APP_LINK_COMMUNITY, commId)
            this.setState({ isLoading: false, data: data })
        } catch {
            this.setState({
                isLoading: false,
                data: null
            })
        }
        const winHeight = document.getElementById('html').clientHeight;
        const boxHeight = document.getElementById('abc').clientHeight;
        if (winHeight > boxHeight) {
            let margin = (winHeight - boxHeight) / 2 - 50;
            margin = margin > 40 ? margin : 30;
            document.getElementById('abc').style.marginTop = (margin - 20) + 'px';
            document.getElementById('abc').style.marginBottom = (margin - 20) + 'px';
        }
    }

    redirectLink = () => {
        let currentLink = "https://www.hb-wallet.com/"
        if (!isMobile) {
            // window.open(currentLink, "_blank")
            this.anchorElement.href = currentLink;
            this.anchorElement.setAttribute('target', '_blank')
        } else {
            try {
                const { commId } = this.props.match.params
                // Detect HB Wallet
                if (window.web3 && window.web3.eth && window.web3.eth.accounts[0]) {
                    this.anchorElement.href = "hbwallet://communityId/" + commId;
                    window.postMessage({ type: 'open_community', data: { id: commId } })
                } else {
                    this.timeout = setTimeout(function () {
                        window.location = currentLink;
                    }, isMobileSafari ? 25 : 3000)
                    this.anchorElement.href = "hbwallet://communityId/" + commId;
                }
            } catch {
                // window.location = currentLink;
                this.anchorElement.href = currentLink;
            }
        }
    }

    render() {
        const { isLoading, data } = this.state
        return (
            <div>
                <div className="imgContainer">
                    <img id="hbIconImg" src={require('./images/HB.png')} alt='icon' width="50px" />
                </div>
                <div className="clear"></div>
                <div className="backgroundContainer" >
                    {isLoading ?
                        <div className="infoContainer" id="abc">
                            <img id="profileImg" src={require('./images/community.png')} alt='profileImg' />
                            <p className="element"><img src={require('./images/loading.gif')} width="30px" alt='loadingGif' /></p>
                            <div className="text ellipsis">
                                <span className="text-concat">
                                    {'Loading community information...'}
                                </span>
                            </div>
                            {/* <button id="btnWeb" onClick={this.redirectLink} rel="nofollow">{isMobile ? 'More details' : 'Open HB Wallet website'}</button> */}
                            <a href={currentLink} ref={(el) => { if (el) { this.anchorElement = el; } }} rel="nofollow" className="pointer">
                                <button id="btnWeb" onClick={this.redirectLink} rel="nofollow" className="pointer">{isMobile ? 'More details' : 'Open HB Wallet website'}</button>
                            </a>
                            <div className="buttonGroup">
                                <img className="btnStoreItem" src={require('./images/appstore.png')} alt='appStore' onClick={this.appleStore} />
                                <img className="btnStoreItem" src={require('./images/googleplay.png')} alt='googlePlay' onClick={this.googlePlay} />
                            </div>
                        </div>
                        :
                        data ?
                            <div className="infoContainer" id="abc">
                                <img id="profileImg" src={`${data && data.community.image ? data.community.image : require('./images/community.png')}`} alt='profileImg' />
                                <p className="element" id="communityName"> {data.community.name}</p>
                                <div className="textall">
                                    <p id="detailcommunity">
                                        {data.community.detail}
                                    </p>
                                </div>
                                <div className='auto-center'>
                                    <QRCode
                                        id="1"
                                        value={'https://profile.hb-chat.work' + this.props.match.url}
                                        size={100}
                                        level={"H"}
                                        includeMargin={true}
                                    />
                                </div>
                                <div className="globalContainer">
                                    <div className="globalContainer">
                                        <img id="channelIcon" src={require('./images/channel_icon.png')} alt='channelIcon' />
                                        <p id="channelText">{data.channelCount + ' Channels'}</p>
                                    </div>
                                    <div className="globalContainer">
                                        <img id="memberIcon" src={require('./images/manageMember.png')} alt='managerMember' />
                                        <p id="memberText"> {data.userCount + ' Users'}  </p>
                                    </div>
                                </div>
                                {/* <button id="btnWeb" onClick={this.redirectLink} rel="nofollow">{isMobile ? 'More details' : 'Open HB Wallet website'}</button> */}
                                <a ref={(el) => { if (el) { this.anchorElement = el; } }} rel="nofollow" className="pointer">
                                    {/* <button id="btnWeb" onClick={this.redirectLink} rel="nofollow" className="pointer">{isMobile ? 'More details' : 'Open HB Wallet website'}</button> */}
                                    <button id="btnWeb" onClick={this.redirectLink} rel="nofollow" className="pointer">{isMobile ? <div className="des-mobile-div"><span className="first-detail-line"> Join community</span> <br /> <span className="second-detail-line">Please tap this button after installed HB Wallet </span> </div> : 'Open HB Wallet website'}</button>
                                </a>
                                <div className="buttonGroup">
                                    <img className="btnStoreItem" src={require('./images/appstore.png')} alt='appStore' onClick={this.appleStore} />
                                    <img className="btnStoreItem" src={require('./images/googleplay.png')} alt='googlePlay' onClick={this.googlePlay} />
                                </div>
                            </div>
                            : <div className="infoContainer" id="abc">
                                <img id="profileImg" src={require('./images/community.png')} alt='profileImg' />
                                <p className="element">{'Community Not Found'}</p>
                                <div className="textAll">
                                    <span className="text-concat">
                                        {'This community’s ID cannot be found.'}
                                    </span>
                                </div>
                                <a href={currentLink} ref={(el) => { if (el) { this.anchorElement = el; } }} rel="nofollow" className="pointer" >
                                    <button id="btnWeb" onClick={this.redirectLink} rel="nofollow" className="pointer">{isMobile ? <div className="des-mobile-div"><span className="first-detail-line"> Join community </span> <br /> <span className="second-detail-line">Please tap this button after installed HB Wallet </span> </div> : 'Open HB Wallet website'}</button>
                                </a>
                                <div className="buttonGroup">
                                    <img className="btnStoreItem" src={require('./images/appstore.png')} alt='appStore' onClick={this.appleStore} />
                                    <img className="btnStoreItem" src={require('./images/googleplay.png')} alt='googlePlay' onClick={this.googlePlay} />
                                </div>
                            </div>
                    }
                </div>

                <div id="bottomWrapper">
                    <img id="bottomImage" src={require('./images/bacoor.png')} alt='companyIcon' width="100px" />
                </div>
            </div>
        );
    }
}
