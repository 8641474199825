import 'whatwg-fetch'
export const GET = 'GET'
export default class ChatServices {
    static async requestData(method, link, queryStr = '') {
        try {
            var response = await fetch(link + queryStr,{
                method
            })
            let responseJson = await response.json()
            if (response.status === 200){
                return responseJson
            }
        }catch(e){
            console.log(e)
        }
    }
}