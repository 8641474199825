import  React, { Component } from 'react';
export default class BaseContainer extends Component {
    
    appleStore = () => {
        window.location = 'https://itunes.apple.com/vn/app/hb-wallet/id1273639572?mt=8'
    }

    googlePlay = () => {
        window.location = 'https://play.google.com/store/apps/details?id=co.bacoor.android.hbwallet'
    }
}