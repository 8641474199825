import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import User from './User'
import Community from './Community'
import Main from './Main'
const RouterConfig = () => (
  <Router>
    <div>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route path="/profileId/:userId" component={User} />
        <Route path="/profileId" component={User} />
        <Route path="/communityId/:commId" component={Community} />
        <Route path="/communityId" component={Community} />
      </Switch>
    </div>
  </Router>
);

export default RouterConfig;